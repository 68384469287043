import { format, parseISO, isValid } from "date-fns";

export const formatDate = (dateString) => {
	const date = parseISO(dateString);

	if (!isValid(date)) {
		return "---";
	}
	return format(date, "MMM d yyyy, h:mma");
};
