import SummaryPanel from "./SummaryPanel";
import Tab from "./Tab";
function Dashboard() {
  return (
    <>
      <SummaryPanel />
      <Tab />
    </>
  );
}

export default Dashboard;
