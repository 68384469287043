import { useForm } from "react-hook-form";
import { Label } from "./ui/label";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Checkbox } from "../components/ui/checkbox";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

import { z } from "zod";
import { useMutation, useQuery } from "react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "./ui/use-toast";
import { ClipLoader } from "react-spinners";
import { roles } from "../roles";
import { ScrollArea } from "../components/ui/scroll-area";
import useAuth from "../hooks/useAuth";

function EditUser({ user }) {
  const axiosPrivate = useAxiosPrivate();
  const { toast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const {auth} = useAuth()
  const formSchema = z.object({
    email: z.string().email({
      message: "Only valid email is allowed",
    }),
    location: z.string({
      message: "Please select user selection",
    }),
    firstName: z.string({
      message: "This field is required",
    }),
    lastName: z.string({
      message: "This field is required",
    }),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: user.firstName || "N/A",
      lastName: user.lastName || "NA",
      email: user.email || "NA",
      location: user.location,
    },
  });
  function onSubmit(data) {
    console.log(data);
    // mutate(data);
  }

  const getlocationbyKey = async (key) => {
    const response = await axiosPrivate.get(`/location/client/${key}`);
    return response;
  };
  const { data, isSuccess } = useQuery({
    queryKey: ["UserlocationbyKey"],
    queryFn: () => getlocationbyKey(user.client_key),
    onSuccess: (data) => {},
    onError: (error) => {
      if (error.code === "ERR_NETWORK") {
        navigate("/", { state: { from: location }, replace: true });
      } else if (
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        navigate("/", { state: { from: location }, replace: true });
      } else {
        toast({
          variant: "destructive",
          title: "Error",
          description: `${error?.response?.data?.message}`,
        });
      }
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const editRole = async (data) => {
    const response = await axiosPrivate.post(`user/roles/${auth?.user?.id}`, data);
    return response;
  };

  const { mutate, isLoading } = useMutation(
    (variables) => editRole(variables),
    {
      onSuccess(data, variables, context) {
        toast({
          variant: "success",
          title: "Success",
          description: `Roles Updated Succcessfully`,
        });
        console.log(data);
      },
      onError(error) {
        if (error.code === "ERR_NETWORK") {
          navigate("/", { state: { from: location }, replace: true });
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          navigate("/", { state: { from: location }, replace: true });
        } else {
          toast({
            variant: "destructive",
            title: "Error",
            description: `${error?.response?.data?.message}`,
          });
        }
      },
    }
  );

  const roleformSchema = z.object({
    roles: z.array(z.string()).refine((value) => value.some((item) => item), {
      message: "Only valid email is allowed",
    }),
  });
  const roleForm = useForm({
    resolver: zodResolver(roleformSchema),
    defaultValues: {
      roles: [...user.role.split(",")],
    },
  });
  function onSubmitloc(data) {
    data.id = user.id;
    mutate(data);
  }

  return (
    <ScrollArea className="h-[97vh]">
      <div className="mb-[25px] h-fit px-[10px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <fieldset>
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <Label>First Name</Label>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder={"first name"}
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <br />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <Label>Last Name</Label>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder={"last name"}
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <br />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <Label>Last Name</Label>
                    <FormControl>
                      <Input
                        type="email"
                        placeholder={"johndoe@mail.com"}
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <br />
              <FormField
                control={form.control}
                name="location"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>location</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="User location" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {isSuccess &&
                          data?.data?.data?.map((locations, index) => (
                            <SelectItem
                              value={locations.gln}
                              key={index}
                              className="text-12">
                              {locations.address}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <br />
              <Button type="submit" className="my-[5px]">
                {false ? (
                  <span className="mx-[20px]">
                    <ClipLoader color="primary" size={20} />
                  </span>
                ) : (
                  <span>Submit</span>
                )}
              </Button>
            </fieldset>
          </form>
        </Form>
      </div>
      <hr />
      <div className="mt-[30px] h-fit px-[10px]">
        <h2>Edit User Roles</h2>
        <Form {...roleForm}>
          <form
            onSubmit={roleForm.handleSubmit(onSubmitloc)}
            className="space-y-6">
            <div className="flex  gap-y-[10px] flex-wrap justify-between">
              <FormField
                control={roleForm.control}
                name="roles"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(roles.sproxil_admin)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([
                                ...field.value,
                                roles.sproxil_admin,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== roles.sproxil_admin
                                )
                              );
                        }}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Sproxil-Admin Role</FormLabel>
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={roleForm.control}
                name="roles"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(roles.encodingRole)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([
                                ...field.value,
                                roles.encodingRole,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== roles.encodingRole
                                )
                              );
                        }}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Encoding Role</FormLabel>
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={roleForm.control}
                name="roles"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(roles.commissioningRole)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([
                                ...field.value,
                                roles.commissioningRole,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== roles.commissioningRole
                                )
                              );
                        }}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Commissioning Role</FormLabel>
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={roleForm.control}
                name="roles"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(roles.packingRole)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([
                                ...field.value,
                                roles.packingRole,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== roles.packingRole
                                )
                              );
                        }}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Packing Role</FormLabel>
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={roleForm.control}
                name="roles"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(roles.shippingRole)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([
                                ...field.value,
                                roles.shippingRole,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== roles.shippingRole
                                )
                              );
                        }}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Shipping Role</FormLabel>
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={roleForm.control}
                name="roles"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(roles.receivingRole)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([
                                ...field.value,
                                roles.receivingRole,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== roles.receivingRole
                                )
                              );
                        }}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Receiving Role</FormLabel>
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={roleForm.control}
                name="roles"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(roles.unpackingRole)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([
                                ...field.value,
                                roles.unpackingRole,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== roles.unpackingRole
                                )
                              );
                        }}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Unpacking Role</FormLabel>
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={roleForm.control}
                name="roles"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(
                          roles.decommissioningRole
                        )}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([
                                ...field.value,
                                roles.decommissioningRole,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== roles.decommissioningRole
                                )
                              );
                        }}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Decommisioning Role</FormLabel>
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={roleForm.control}
                name="roles"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(roles.dispensingRole)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([
                                ...field.value,
                                roles.dispensingRole,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== roles.dispensingRole
                                )
                              );
                        }}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Dispense Role</FormLabel>
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={roleForm.control}
                name="roles"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(roles.all_roles)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([...field.value, roles.all_roles])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== roles.all_roles
                                )
                              );
                        }}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>All Roles</FormLabel>
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <Button type="submit">
              {isLoading ? (
                <span className="mx-[20px]">
                  <ClipLoader color="primary" size={20} />
                </span>
              ) : (
                <span>Submit</span>
              )}
            </Button>
          </form>
        </Form>
      </div>
    </ScrollArea>
  );
}

export default EditUser;
