import { useMutation, useQuery, useQueryClient } from "react-query";
import useAxiosPrivate from "./useAxiosPrivate";

const notificationsKey = "user-notifications";

export const useGetUserNotifications = (userId, isRequestEnabled) => {
	const axiosPrivate = useAxiosPrivate();

	const queryResponse = useQuery({
		queryKey: [notificationsKey],
		queryFn: () => axiosPrivate.get(`user/mail/${userId}`),
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		enabled: userId !== "" && isRequestEnabled,
	});

	return {
		isError: queryResponse.isError,
		isSuccess: queryResponse.isSuccess,
		isLoading: queryResponse.isLoading,
		data:
			queryResponse.data?.data?.data?.filter((notification) => notification.status === 0) || [],
	};
};

export const useClearNotifications = ({ onSuccess, onError }) => {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient()

	const mutation = useMutation((userId) => axiosPrivate.patch(`user/mail/${userId}`), {
		onSuccess(data) {
			onSuccess && onSuccess(data);
            queryClient.invalidateQueries([notificationsKey])
		},
		onError(error) {
			onError && onError(error || "unable to clear notifications");
		},
	});

	return {
		isLoading: mutation.isLoading,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		clear: mutation.mutate,
	};
};
