import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../index.css";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery } from "react-query";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import { CircleUser } from "lucide-react";
import Notification from "../components/Notification";

function Home() {
  const { setAllClients, auth } = useAuth();
  // const [errorResponse, setErrorResponse] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  useQuery({
    queryKey: ["clients-locations"],
    queryFn: () =>
      axiosPrivate.get(`client/user-clients/?clients=${auth.user.userClients}`),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setAllClients(data.data.data);
    },
    onError: (error) => {
      console.log(error);
      if (error.response.status === 401)
        navigate("/", { state: { from: location }, replace: true });
      else console.log(error);
    },
  });
  return (
    <>
      <div className="flex  w-[100vw]">
        <section className=" w-[20vw]">
          <SideBar />
        </section>

        <section className="w-[78vw]">
          <nav className="sticky top-0 right-0 h-[55px] w-[full]  z-20">
            <div id="container" className=" flex justify-end w-full h-full">
              <div className="w-[320px] flex items-center gap-[14px] bg-white h-full p-[10px]">
                {" "}
                <Notification />
                <CircleUser size={20}/>
                <span>{auth?.user?.email}</span>
              </div>
            </div>
          </nav>
          <main className=" my-[20px] w-[96%] m-auto min-h-[70vh]">
            <Outlet />
          </main>
          <Footer />
        </section>
      </div>
    </>
  );
}

export default Home;
